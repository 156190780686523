import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class QueryService {

  oQueryParams = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { 
    this.route.queryParams.subscribe(q => {
      this.oQueryParams = q
    });
  }

  storeQueryParams(queryParams: any) {
    let nQueryParams = Object.assign({}, this.oQueryParams, queryParams);
    Object.keys(nQueryParams).map(k => {
      if (nQueryParams[k] === undefined) delete nQueryParams[k]
    })
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: nQueryParams,
        replaceUrl: true
      });
  }

  deleteQueryParams(queryName = []) {
    let nQueryParams = {};
    Object.keys(this.oQueryParams).map(k => {
      if (k in queryName) return;
      nQueryParams[k] = this.oQueryParams[k];
    })

    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: nQueryParams,
        replaceUrl: true
      });
  }

}
